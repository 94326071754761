.drawer {
  $body-width: 300px;
  $body-width-vw: 85vw;

  @include transition(all 250ms ease-in-out);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  z-index: 11;

  &__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    cursor: pointer;
  }

  &__body {
    @include transition(left 250ms ease-in-out);
    position: absolute;
    top: 0;
    bottom: 0;
    left: $body-width * -1;
    width: $body-width;
    width: $body-width-vw;
    background-color: #ffffff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .5);
    overflow: auto;

    .drawer--active & {
      left: 0;
    }
  }

  &__body-top {
    padding: 20px;
    text-align: center;
    border-bottom: #eee solid 1px;
  }

  &__body-middle {
    padding: 20px;
    border-bottom: #eee solid 1px;
    background-color: $main-red;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .3);
  }

  &--active {
    opacity: 1;
    visibility: visible;
  }
}