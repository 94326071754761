.carousel {
  background: #FAFAFA;

  @media (max-width: $screen-sm-max) {
    margin-top: 72px;
    right: 0;
    text-align: center;

    .carousel-cell {
      min-height: 200px;
    }
  }
}

.carousel-cell {
  width: 100%;
  min-height: 400px;
  max-height: 400px;
  margin-right: 10px;
  background: #333;
}

.flickity-viewport {
  transition: height 0.2s;
}


.carousel-cell-image {
  display: block;
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

/* fade in lazy loaded image */
.carousel-cell-image.flickity-lazyloaded,
.carousel-cell-image.flickity-lazyerror {
  opacity: 1;
}



/* position dots in carousel */
.flickity-page-dots {
  top: 10px;
  text-align: right;
  display: inline-table;
  right: 20px;
}
/* white circles */
.flickity-page-dots .dot {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: transparent;
  border: 2px solid white;
}
/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  background: white;
}


