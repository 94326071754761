.fluid-image {
  position: relative;
  overflow: hidden;
  display: block;

  &__img-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
  }

  &__img {
    position: relative;
    z-index: 2;
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  &__ratio {
    display: block;
    width: 100%;
    visibility: hidden;
  }

  &__bg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent no-repeat center center;
    background-size: cover;

    &--blur {
      opacity: $fluid-images-bg-opacity;
      @include blur($fluid-images-bg-blur);
      @include scale($fluid-images-bg-scale);
    }
  }

  &--fullheight {
    height: 100%;
  }

  &--flex {
    @include flex(1);
  }
}