.list-product {
  padding-top: 15px;
  padding-bottom: 30px;
  background-color: #f5f8fc;

  &__container {
    @extend .container;
  }

  &__row {
    @extend .row;
  }

  &__item {
    @extend .col-lg-3;
    @extend .col-md-4;
    @extend .col-sm-6;
    @extend .col-xs-12;

    @media (min-width: 470px) and (max-width: $screen-xs-max) {
      width: 50%;
    }

    margin-top: 15px;
  }

  &__cover {
    position: relative;
    background-color: $body-bg;

    &:after{
      content: ' ';
      display: block;
      height: 1px;
      background: #e2e3e7;
    }
  }

  &__body, &__footer {
    padding-top: 15px;

    background-color: $body-bg;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);

    .nav_link {
      @extend .nav_link--no-wrap;
      @extend .text-center;
    }
  }

  &__footer {
    padding: 15px 37px;

    .list-product__btn, .list-product__form {
      width: 100%;
    }
    .input--number {
      width: 60px;
    }
  }

  &__message {
    position: absolute;
    top:0;
    left:0;
    color: $text-white-color;
    padding: 5px 20px;
    background-color: $btn-success-bg;
    border-color: $btn-success-border;
    z-index: 2;

    &--fb {
      @extend .fa;
      @extend .fa-facebook;
      background-color: $facebook-color;
      right: 0;
      padding: 8px 11px;
      left: initial;

      &:hover, &:active, &:focus {
        background-color: darken($facebook-color, 20%);
        color: $text-white-color;
      }
    }

    &--stock {
      top: initial;
      bottom: 0;
      right: 0;
      background-color: #8c8c8c;
      text-align: center;
    }
  }

  &__globe {
    @extend .list-product__message;
    border-radius: 100%;
    right: 30px;
    top: 20px;
    left: auto;
    font-size: 11px;
    width: 50px;
    height: 50px;
    padding: 20px 5px;
    background: #000;

    &:after {
      content: ' ';
      display: block;
      border: 16px solid transparent;
      border-right-color: #000;
      width: 0;
      height: 0;
      transform: rotate(319deg);
      margin-top: -4px;
      margin-left: -22px;
    }
  }

  &__btn {
    @extend .btn;
    @extend .btn-success;

    &--default {
      background-color: #8c8c8c;
      border-color: #8c8c8c;
      color: $text-white-color;

      &:focus, &:hover {
        border-color: #8c8c8c;
        background-color: darken(#8c8c8c, 5%);
      }
      &:active {
        border-color: #8c8c8c;
        background-color: darken(#8c8c8c, 10%);
      }
    }

    &--reverse {
      background-color: $btn-success-color;
      border-color: #8c8c8c;
      color: $btn-success-bg;

      &:focus, &:hover {
        border-color: #8c8c8c;
        background-color: darken(#8c8c8c, 5%);
      }
      &:active {
        border-color: #8c8c8c;
        background-color: darken(#8c8c8c, 10%);
      }
    }

    &--reverse-2 {
      @extend .list-product__btn--reverse;
      color: #8c8c8c;
    }
  }

  &__form {
    @extend .box;

    &.disabled:after {
      position: relative;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}

.price {
  color: $btn-success-bg;
  font-size: 20px;
  font-weight: 800;

  &--text {
    display: block;
  }

  &--old {
    font-size: 15px;
    color: #CCC;
    text-decoration: line-through;
  }

  span {
    font-weight: normal;
    font-size: $font-size-base;
  }
}