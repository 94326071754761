.nav {
  @include flexbox();
  @include flex-direction(row);
  @include justify-content(space-between);

  @media (max-width: $screen-sm-max) {
    @include flex-direction(column);
  }
}
.nav--wrap {
  padding-right: 0;
  @include flex-wrap(wrap);

  > .nav_item {
    margin: 0;
    padding: 0;
    flex: 0 0 234px;
  }
}

.nav--header {
  @include justify-content(flex-start);
  margin-top: 10px;

  .nav_title:after {
    display: none;
  }
}

.nav_item {
  @include flexbox();
  @include flex-direction(column);
  width: 100%;
  padding: 0 10px;
}

.nav_content {
  @include flexbox();
  @include flex-direction(column);
  padding: 10px 0;

  &--hide {
    display: none;
  }
}

.nav_text {
  font-size: $text-size;
  padding-bottom: 2px;
  line-height: 2em;
}

.nav_link {
  @extend .nav_text;

  &--active {
    color: $main-red;
  }
  &--no-wrap {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover, &:active {
    color: $main-red;
    text-decoration: none;
  }
}

.nav_link-parent {
  @include flex(1 1 auto);

  width: auto;
  padding: 0;

  .nav_link {
    padding: 0 10px;

    &:after {
      @include transition(all linear .5s);

      width: 0;
      height: 2px;
      content: ' ';
      display: block;
      background-color: $main-red;
    }
  }

  &:hover, &:focus {
    > .nav_link {
      color: $main-red;
      background-color: #f1f2f2;
      position: relative;

      &:after {
        width: 100%;
      }
    }
    .nav_content--fixed {
      opacity: 1;
      overflow: visible;
      visibility: visible;
    }
  }
}

.nav_content--fixed {
  @include transition(all ease 0.5s);

  position: absolute;
  width: 100%;
  top: 171px;
  left: 0;
  right: 0;
  background: #FFF;
  box-shadow: 1px 5px 10px #000;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  z-index: 99999;

  &.active {
    opacity: 1;
    overflow: visible;
    visibility: visible;
  }
}

.nav_title {
  font-size: $title-size;
  color: #FFF;

  &--dark {
    color: $text-color;
  }

  &:after {
    content: ' ';
    width: 100%;
    height: 1px;
    background-color: $main-red;
    display: block;
    margin-top: 10px;
  }
}