.top-search {
  $top-search-height: 55px;

  @include transition(all 250ms ease-in-out);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  z-index: 12;

  &__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    cursor: pointer;
}

  &__body {
    @include transition(top 250ms ease-in-out);
    @include flexbox;
    @include flex-direction(column);
    position: absolute;
    top: $top-search-height * -1;
    right: 0;
    left: 0;
    height: $top-search-height;
    background-color: #ffffff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .5);
    padding: 5px;

    .top-search--active & {
      top: 0;
  }
}

  &--active {
    opacity: 1;
    visibility: visible;
}
}