.box {
  @include flexbox();

  &--center {
    @include align-items(center);
  }

  &--between {
    @include justify-content(space-between);
  }

  &--wrap {
    @include flex-wrap(wrap);
  }

  &--icon {
    padding: 20px 10px;
    color: lighten($text-color, 50%);

    .box {
      @extend .box--center;
      @extend .box--between;
    }
  }
}