.social {
  background-color: $main-red;

  &__item {
    color: $text-white-color;
  }

  &__container {
    @include flexbox();
    @include justify-content(flex-end);
    @extend .container;

    &:after, &:before {
      content: none;
    }
  }

  &__item:hover, &__item:active, &__item:focus {
    color: $text-color;
  }
}