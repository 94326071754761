.drawer-nav {
  &__list {
    @extend .list-unstyled;
  }

  &__item {
    @extend .list-unstyled;
    &.active {
      background-color: $main-red;

      .drawer-nav__link {
        color: #ffffff;
      }
    }

    & + & {
      border-top: #eee solid 1px;
    }
  }

  &__link {
    display: block;
    padding: 20px;

    @include active-button($main-red);
  }

  &__icon {
    float: right;
    font-size: 20px;
  }
}