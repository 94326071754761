.input {
  @extend .form-control;

  &--dark {
    border-color: $footer-color;
    color: $text-white-color;
    background-color: $footer-color;
  }

  &--number {
    &.quantity {
      position: relative;

      input {
        width: 65px;
        height: 34px;
        line-height: 1.65;
        float: left;
        display: block;
        padding: 0;
        margin: 0;
        padding-left: 20px;
        border: 1px solid #eee;

        &:focus {
          outline: 0;
        }
      }
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    .quantity-nav {
      float: left;
      position: relative;
      height: 32px;
    }

    .quantity-button {
      position: relative;
      cursor: pointer;
      border-left: 1px solid #eee;
      width: 20px;
      text-align: center;
      color: #333;
      font-size: 13px;
      font-family: "Trebuchet MS", Helvetica, sans-serif !important;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }

    .quantity-button.quantity-up {
      position: absolute;
      height: 50%;
      top: 0;
      border-bottom: 1px solid #eee;
    }

    .quantity-button.quantity-down {
      position: absolute;
      bottom: -1px;
      height: 50%;
    }
  }
}