.header-mobile {
  @media (max-width: $screen-sm-max) {
    visibility: visible;
  }

  @include flexbox;
  @include justify-content(space-between);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 6px 7px;
  background-color: $body-bg;
  z-index: 10;
  visibility: hidden;

  &__logo {}

  &__navbar {
    @include flexbox;
    @include align-items(center);
  }

  &__search {
    color: #000;
    padding: 0 13px;
    font-size: 22px;
    line-height: 1;
  }

  &__hamburger {
    background-color: $main-red;

    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include active-button($main-red);
  }
}