.small-search {
  @include flexbox;
  width: 100%;
  height: 100%;

  .top-search & {
    @include flex(1);
  }

  .drawer & {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .3);
  }

  &__input {
    @include flex(1);
    border: #eee solid 1px;
    background-color: #ffffff;
    padding: 10px;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  &__btn {
    padding: 5px 10px;
    color: #fff;
    background-color: $main-red;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    @include active-button($main-red);
  }
}