.footer {
  padding: 1rem;
  background-color: $footer-color;
  background: $footer-background;

  &--white {
    color: lighten($text-color, 60%);
    background: #FFFFFF;
    font-size: 13px;
  }

  &_container {
    @extend .container;
  }

  &__logos {
    width: 130px;
    height: 50px;
    background: url("../images/footer_logos.png");
    background-repeat: no-repeat;
    background-size: contain;
    align-self: flex-end;
    margin: 0 0 0 auto !important;
  }
  .nav_text {
    color: $text-white-color;
  }
  a {
    color: $text-white-color;

    &.nav_link--active, &:active, &:hover, &:focus {
      color: $main-red;
    }
  }
}

.container--inline {
  @include flexbox();
  @include align-items(center);

  p {
    margin: 0;
  }
}

.banner {
  @extend .box;
  @extend .container;
  @include justify-content(space-between);
  @include align-items(center);
  @include flex-wrap(wrap);

  text-align: center;
  background-color: $main-red;
  padding: 5px 30px;
  font-size: 12px;
  color: $text-white-color;
  &:after, &:before {
    content: none;
  }

  .box {
    @include align-items(center);
    @include justify-content(flex-start);

    .badge,
    .footer__icon,
    .text {
      margin-right: 10px;
    }
  }

  .footer__icon {
    font-size: 25px;
  }

  .badge {
    width: 20px;
    height: 20px;
    padding: 3px;
    color: $main-red;
    font-size: 100%;
    vertical-align: middle;
    border-radius: 100%;
    background-color: transparentize(#ffffff, .5);
  }

  p {
    margin: 0;
    padding: 0;
  }

  @media (max-width: $screen-sm-max) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.dropdown--white-open.open a {
  background-color: transparent !important;
  color: $main-red;
}