$main-red: #bf1311;
$footer-color: #211915;
$text-color: #000000;
$text-white-color: #FFFFFF;

$facebook-color: rgb(59, 89, 152);

$body-bg: #FFFFFF;
$body-color: #000000;

$link-color: $text-color;
$link-hover-color: $main-red;
$link-hover-decoration: none;

$hamburger-padding-x: 10px;
$hamburger-padding-y: 10px;
$hamburger-layer-height: 3px;
$hamburger-layer-width: 30px;
$hamburger-layer-color: $text-white-color;
$badge-bg: $main-red;

$fluid-images-bg-blur: 5;
$fluid-images-bg-scale: 1.2;
$fluid-images-bg-opacity: .7;

$btn-primary-bg: $main-red;
$btn-primary-color: $text-color;

$border-radius-base: 0;

$input-border-focus: $main-red;

$title-size: 18px;
$text-size: 13px;

$font-family-sans-serif:  'Titillium Web', sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;

$footer-background: url('../images/footer_bg.jpg');