@mixin blur($px: 0) {
  -webkit-filter: blur(#{$px}px) !important;
  -ms-filter: blur(#{$px}px) !important;
  -moz-filter: blur(#{$px}px) !important;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'blur\'><feGaussianBlur stdDeviation=\'#{$px}\' /></filter></svg>#blur");
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='#{$px}');
}

@mixin active-button($color) {
  @include transition(background-color 150ms ease-in);

  &:focus {
    background-color: darken($color, 5%);
  }
  &:active {
    background-color: darken($color, 10%);
  }
}