.icon {
  background: url('../images/sprite-icons.png');
  width: 29px;
  height: 23px;
  
  @media (max-width: $screen-xs-max) {
    display: none;
  }

  &--papers {
    background-position-x: 0;
  }

  &--drop {
    background-position-x: -35px;
  }

  &--pencil {
    background-position-x: -70px;
  }

  &--calc {
    background-position-x: -102px;
  }

  &--scissors {
    background-position-x: -136px;
  }

  &--computer {
    background-position-x: -168px;
  }

  &--files {
    background-position-x: -201px;
  }

  &--product {
    background-position-x: -235px;
  }

  &--rule {
    background-position-x: -265px;
  }

  &--cup {
    background-position-x: -301px;
  }
}