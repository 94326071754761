.header {
  background-color: #ffffff;
  position: relative;

  &:before {
    content: ' ';
    height: 2px;
    width: 100%;
    background-color: $main-red;
    display: block;
  }

  &:after {
    content: ' ';
    display: block;
    height: 1px;
    background: #e2e3e7;
  }
}

.header_container {
  @extend .container;
  background-color: $body-bg;
}

.header_logo {
  flex: .1 1 65px;
}

.header_searcher {
  @extend .box;
  @include align-self(flex-end);
  @include flex();

  padding: 0 20px;
}

.header_icon {
  font-size: 18px;
  margin-right: 10px;
}

.header_account {
  @extend .box;
  @include flex-direction(column);
  @include justify-content(space-between);

  padding-top: 20px;
}